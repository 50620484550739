<script>
export default {
  name: 'AccountDeletion'
}
</script>

<template>
  <div class="card">
    <header class="card-header">
      <h4 class="card-header-title">
        Linctech Global
      </h4>
    </header>
    <div class="card-content">
      <div class="content">
        <h5>How to Request Account Deletion:</h5>
        <p>
          To ensure user privacy and data control, we provide a simple process for users to request the deletion of
          their
          accounts. Follow these steps to initiate the account deletion request:
        </p>
        <ol>
          <li>Open Linctech Global app on your device and login to your account.</li>
          <li>On the bottom navigation bar, tap the profile icon to open the profile page.</li>
          <li>Scroll down until the "Delete Account." button comes into view</li>
          <li>Tap on the "Delete Account" button. You will be prompted to confirm your decision. Please read the
            on-screen
            instructions carefully before proceeding.
          </li>
          <li>
            Once confirmed, your account deletion request will be submitted and you will be logged out once the deletion
            process completes.
          </li>
        </ol>

        <h5>Data Deletion and Retention Policy:</h5>
        <p>
          We are committed to safeguarding user data and have established a transparent data deletion and retention
          policy.
          Here's an overview of the types of data that are deleted and the retention period for certain data:
        </p>
        <ol>
          <li>
            Personal Information: Upon account deletion, all personal information provided during the registration
            process,
            such as name, email address, and profile details, will be permanently deleted from our servers.
          </li>
          <li>
            Usage Data: We retain certain usage data, such as app usage history and interactions, for 1 day to analyze
            app
            performance and improve user experience. However, this data is anonymized and cannot be linked back to
            individual users.
          </li>
        </ol>

        <h5>Data Security:</h5>
        <p>
          Rest assured that we take data security seriously. All user data is encrypted during transmission and at rest
          to protect it from unauthorized access.
        </p>

        <p>
          For any concerns or inquiries regarding data privacy and deletion, please contact our support team at
          sales@linctechonline.com. We aim to respond to all inquiries within 1 day.
        </p>
        <p>
          Your privacy is our priority, and we appreciate your trust in Linctech Global. Thank you for using our app!
        </p>
        <hr>
        <p>
          <b>Linctech Global</b><br>
          <a href="https://www.linctechonline.com/">
            https://www.linctechonline.com
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
