<script>
export default {
  name: 'Clover',
  data () {
    return {
      isLoading: false,
      errors: {},
      success: '',
      form: {
        merchant_id: this.$route.query.merchant_id,
        employee_id: this.$route.query.employee_id,
        client_id: this.$route.query.client_id,
        code: this.$route.query.code
      }
    }
  },
  created () {
    this.submit()
  },
  methods: {
    submit () {
      this.isLoading = true
      this.axios.post('/v2/clover/access-token', this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.form = {}
          this.success = true
          this.$buefy.snackbar.open({
            message: 'Access token saved. Please proceed to app',
            queue: false
          })
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
          this.success = false
        })
    }
  }
}
</script>

<template>
  <div class="card">
    <header class="card-header">
      <h4 class="card-header-title">
        Clover Oauth Callback
      </h4>
    </header>
    <div class="card-content">
      <b-notification
        type="is-success"
        v-if="success">
        Access token has been generated. Please proceed to app and initiate the shipment payment transaction before it expires in 20 minutes
      </b-notification>
      <b-notification
        type="is-danger"
        v-if="success == false && !isLoading">
        Could not generate an access token. Please try again!
      </b-notification>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true">
        <b-icon
          pack="fas"
          icon="sync-alt"
          size="is-large"
          custom-class="fa-spin">
        </b-icon>
      </b-loading>
    </div>
  </div>
</template>

<style scoped>

</style>
